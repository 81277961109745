// core
import { useTranslation } from "react-i18next";
// utils

// components
import StarBorder from "./../../icons/StarBorder";
import StarFill from "./../../icons/StartFill";
import InfoCircle from "../../icons/InfoCircle";
import { MEDIA } from "../../../utils/constants";
import InfoImg from "./infoImg.png";

// styles
import "./index.scss";
import img from "./img.png";
import imgM from "./imgM.png";
import {useWindowSize} from "react-use";

const PricePool = ({ data, className, dataRef }) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();
  const cardSettingRate = [
    {
      bgColor: "cardBlue100",
      stars: <StarBorder/>,
    }, {
      bgColor: "cardBlue150",
      stars: <StarFill/>,
    },{
      bgColor: "cardBlue200",
      stars: (
          <div className="price-pool__referal-box__row-star">
            <StarFill/>
            <StarFill/>
          </div>
      ),
    },{
      bgColor: "cardBlue250",
      stars: (
          <div className="price-pool__referal-box__row-star">
            <StarFill/>
            <StarFill/>
            <StarFill/>
          </div>
      ),
    },
  ];

  return (
      <div className={"price-pool " + (className ? className : "")}>
        <div className="app__wrapper">
        <div
          className="price-pool__title"
          dangerouslySetInnerHTML={{
            __html: data.title,
          }}
        />

        <div className="price-pool__desc">
          <p>{data.description}</p>
        </div>

        <div className="price-pool__referal-box">
          {
            dataRef.map((item, index) => {
              return (
                  <div
                      className={`price-pool__referal-box__card ${cardSettingRate[item.rating].bgColor}`}
                      data-aos="fade-up"
                      data-aos-offset="150"
                      data-aos-delay={(index + 1) * 200}
                  >
                    {cardSettingRate[item.rating].stars}
                    <h5>{item.title}</h5>
                    <div className="price-pool__referal-box__line"/>
                    <div className="price-pool__referal-box__wrap-info">
                      <span>{t("label.conditions")}</span>
                      <p>{item.requirements}</p>
                    </div>

                    <div className="price-pool__referal-box__line"/>
                      <div>
                          {/*<span>Бонуси</span>*/}
                          <span>{t("label.bonuses")}</span>
                          <p
                              dangerouslySetInnerHTML={{
                                  __html: item.bonus,
                              }}
                          ></p>
                      </div>
                  </div>
              )
            })
          }
        </div>
            <p className="price-pool__referal-box__note">
                {data.note}
            </p>

            <div
                className="price-pool__banner"
            data-aos="fade-up"
            data-aos-offset="150"
            data-aos-delay="700"
        >
            {
                width > MEDIA.M768 ? <img src={data.image1 || img}/> : <img src={data.image2 ||imgM}/>
            }
        </div>

            {
                width < MEDIA.M768 ? <p className="price-pool__blue-txt">{data.image_text}</p> : null
            }

        <div
            className="price-pool__cashback-info"
            data-aos="fade-up"
            data-aos-offset="150"
            data-aos-delay="1200"
        >
          <div className="price-pool__wrapTitle">
              <img src={InfoImg} />
            <h5>
                {data.conditions}
            </h5>
          </div>

          <div className="price-pool__cashback-info__tags">
              {data.points.map((item) => (
                  <span>{item.title}</span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePool;
