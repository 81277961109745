import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.kolo || initialState;

export const selectKoloBlocks = createSelector(
  [selectDomain],
  (koloState) => koloState.blocks
);
export const selectKoloContent = createSelector(
  [selectDomain],
  (koloState) => koloState.content
);
