// locale
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// styles
import "./index.scss";
import { useWindowSize } from "react-use";

const HeaderNav = ({ onClick }) => {
  // translation hook
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();

  const gotoAnchor = (anchor) => {
    if (location.pathname === "/") {
      let elem = document.getElementById(anchor);

      setTimeout(() => {
        if (elem) {
          if (width <= 1024) onClick();
          window.scrollTo({ top: elem.offsetTop, behavior: "smooth" });
        }
      }, 0);
    } else {
      navigate(`/#${anchor}`);
    }
  };
  return (
    <ul className="header-nav">
      <li>
        <a onClick={() => gotoAnchor("about")} title={t("nav.aboutToken")}>
          {t("nav.aboutToken")}
        </a>
      </li>
      {/*<li>*/}
      {/*  <RouterLink to={"/trust-wallet"} title={t("nav.trustWallet")}>*/}
      {/*    {t("nav.trustWallet")}*/}
      {/*  </RouterLink>*/}
      {/*</li>*/}
      {/*<li>*/}
      {/*  <RouterLink to={"/kolo-card"} title="KOLO">*/}
      {/*    KOLO*/}
      {/*  </RouterLink>*/}
      {/*</li>*/}
      <li>
        <RouterLink to={"/weld-card"} title={t("nav.cryptoCard")}>
          {t("nav.cryptoCard")}
        </RouterLink>
      </li>
      <li>
        <RouterLink to={"/business"} title={t("nav.business")}>
          {t("nav.business")}
        </RouterLink>
      </li>
      <li>
        <RouterLink to={"/reserves"} title={t("nav.reserves")}>
          {t("nav.reserves")}
        </RouterLink>
      </li>
      <li>
        <RouterLink to={"/team"} title={t("nav.team")}>
          {t("nav.team")}
        </RouterLink>
      </li>
      <li>
        <RouterLink to={"/news"} title={t("nav.news")}>
          {t("nav.news")}
        </RouterLink>
      </li>
      <li>
        <a onClick={() => gotoAnchor("faq")} title={t("nav.faq")}>
          {t("nav.faq")}
        </a>
      </li>
    </ul>
  );
};

export default HeaderNav;
