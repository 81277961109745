export default () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3063 7.13902C16.8396 2.59452 18.1063 0.322266 20 0.322266C21.8937 0.322266 23.1604 2.59451 25.6937 7.13901L26.3491 8.31473C27.069 9.60614 27.4289 10.2518 27.9901 10.6779C28.5513 11.1039 29.2503 11.2621 30.6482 11.5784L31.9209 11.8663C36.8403 12.9794 39.3 13.5359 39.8852 15.4177C40.4704 17.2996 38.7935 19.2604 35.4398 23.1821L34.5722 24.1967C33.6191 25.3111 33.1426 25.8683 32.9283 26.5577C32.7139 27.247 32.7859 27.9905 32.93 29.4774L33.0612 30.8311C33.5682 36.0635 33.8218 38.6797 32.2897 39.8427C30.7576 41.0057 28.4546 39.9454 23.8487 37.8246L22.657 37.2759C21.3481 36.6733 20.6937 36.372 20 36.372C19.3063 36.372 18.6519 36.6733 17.343 37.2759L16.1513 37.8246C11.5454 39.9454 9.24235 41.0057 7.7103 39.8427C6.17824 38.6797 6.43176 36.0635 6.93879 30.8311L7.06997 29.4774C7.21405 27.9905 7.28609 27.247 7.07173 26.5577C6.85736 25.8683 6.38085 25.3111 5.42784 24.1967L4.56019 23.1821C1.2065 19.2604 -0.470351 17.2996 0.114842 15.4177C0.700035 13.5359 3.15971 12.9794 8.07907 11.8663L9.35177 11.5784C10.7497 11.2621 11.4487 11.1039 12.0099 10.6779C12.5711 10.2518 12.9311 9.60614 13.6509 8.31473L14.3063 7.13902Z"
      fill="#376FE5"
    />
  </svg>
);
