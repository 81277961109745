import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getKoloBlocks = createAsyncThunk(
  `kolo/getKoloBlocks`,
  async () => {
    const url = "kolo/blocks";
    return await $apiClient.get(url);
  }
);

export const getKoloContent = createAsyncThunk(
  `kolo/getKoloContent`,
  async () => {
    const url = "kolo?include=icons";
    return await $apiClient.get(url);
  }
);
